import React from 'react';

function HomePage() {
  return (
    <section>
      <h2>Welcome to indpon.com</h2>
      <p>(a website made by indpon)</p>
    </section>
  );
}

export default HomePage;
