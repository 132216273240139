import React from "react";

import "../Static/Firstblog.css"

export default function Firstblog() {
    return (
        <>
            <div>
                <h2 className="title" >My first blog</h2>
            </div>

            <div>
                <p className="blogtext">
                    Hey, my name is indpon, and this if my first  <br />
                    blog. I am a web developer and I love to code. <br />
                    I am a web developer and I love to code. <br />
                    I am a web developer and I love to code. <br />
                    I am a web developer and I love to code. <br />
                    I am a web developer and I love to code. <br />
                    I am a web developer and I love to code. <br />
                    I am a web developer and I love to code. <br />
                    I am a web developer and I love to code. <br />
                </p>
            </div>
        </>
    )
}