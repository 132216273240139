// import react and useState and export default function Hunt
import Stepone from './Stepone.jsx';
import Secondstep from './Secondstep.jsx';
import Thirdstep from './Thirdstep.jsx';
import Fourthstep from './Fourthstep.jsx';

export default function Hunt() {
    if (!window.location.hash) { 
        return <Stepone />
    } else if (window.location.hash === '#completedstepone') {
        return <Secondstep />
    } else if (window.location.hash === "#completedsecondstep") {
        return <Thirdstep />
    } else if (window.location.hash === "#fourthstep") {
        return <Fourthstep />
    }
}