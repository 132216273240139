import React from 'react';

export default function Thirdstep() {

    const handleChange = (e) => {
        if (e.target.value === "JNS040KApcansLAnc00OlK") {
            window.location.hash = "#fourthstep";
        }
    }

    return (
        <div>
            <h1>Im surprised you've made it this far! Heres what you need to do next: go to <a href="https://twitch.tv/orbemorder">here</a> Then type in !code. </h1>
            <input onChange={handleChange} type="text" placeholder="Enter your code here" />
        </div>
    )
}