import React from 'react';

export default function Secondstep() {

    console.log("27ab8xmap")

    function handleChange(e) {
        // check if the code is 27ab8xmap
        if (e.target.value === "27ab8xmap") {
            window.location.hash = "#completedsecondstep"
        }
    }

    return (
        <div>
            <h1>Great job. I see you know how to do this... But do you know how to do this? Hint: Ctrl + Shift + I and then look around...</h1>
            <input onChange={handleChange} type="text" placeholder="Enter the code." />
        </div>
    )
}