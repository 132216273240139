import { useState } from 'react'; // Assuming you're in a React component

export default function CreateUser() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [response, setResponse] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

      fetch('/api/v1/createuser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      }).then((data) => {
        return data.json()
      }).then((data) => {
        if (data.error) {
            console.error(data.error.reason)
            setResponse(data.error.reason)
        } else if (data.success) {
            console.log(data.success.reason)
            setResponse(data.success.reason)
        }
      }).catch((error) => {
        console.error(error)
      })

  }


  return (
    <div>
      <form onSubmit={handleSubmit}> 
        <label htmlFor="username">Username:</label>
        <input 
          type="text" 
          id="username" 
          name="username" 
          value={username}
          onChange={(e) => setUsername(e.target.value)} 
        /> <br />

        <label htmlFor="password">Password:</label>
        <input 
          type="password" 
          id="password" 
          name="password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /> <br />

        <input type="submit" value="Create User" />

        <p>{response}</p>
      </form>
    </div>
  );
}